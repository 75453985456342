
.MuiCard-root {
  padding: 7px 7px 7px 7px;
  background-color: rgba(0, 0, 0, 0.2);
}

a {
  padding-left: 5px;
  color: white;
}

a[target="_blank"]:after {
  content: "⤤";
}

body {
  font-family: "Lato", sans-serif;
}

.raised-div {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.66);
  color: white;
  background-color: rgba(0,25,0,1.0);
  padding: 12px 12px 12px 12px;
  border-radius: 5px 5px 5px 5px;
}